{
  "Auth": {
    "Common": {
      "Password": "password"
    },
    "Errors": {
      "UsernameOrPassword": "Username or password is incorrect"
    },
    "Login": {
      "Title": "login",
      "EmailPlaceholder": "Enter email",
      "PasswordPlaceholder": "Enter password",
      "Heading": "Experience <br /> with <span class='text-3xl'> Whiteshark retail </span>"
    },
    "Register": {
      "Title": "register"
    },
    "ForgotPassword": {
      "Title": "quên mật khẩu"
    }
  },
  "Confirmation": {
    "SaveChange": "Do you want to save unsaved change?"
  },
  "NotFound": {
    "PageNotFound": "Page not found",
    "NotAuth": "You can not access this page. Contact admin for help"
  },
  "Status": {
    "Active": "Active",
    "Inactive": "Inactive"
  },
  "Errors": {
    "Required": "This field is required.",
    "InvalidEmail": "Invalid email.",
    "InvalidPhone": "Invalid phone number.",
    "MustMatch": "{{control}} must match {{matchingControl}}",
    "MinLength": "Must be at least {{minLength}} characters",
    "ExistingCode": "The code is already exist",
    "InvalidAddress": "The address must be selected from the google map",
    "InvalidValue": "Invalid value",
    "AutoComplete": "This field must be selected from the list",
    "Whitespace": "Cannot contain only whitespace"
  },
  "Pagination": {
    "Display": "Display",
    "Page": "page",
    "TotalRecords": "Total <span class='font-medium text-primary'>{{value}}</span> records"
  },
  "Action": {
    "Title": "Action",
    "Lock": "Lock",
    "Unlock": "Unlock",
    "Delete": "Delete",
    "Edit": "Edit",
    "Add": "Add",
    "Update": "Update",
    "Reset": "Reset",
    "Save": "Save",
    "AddPrices": "Add new prices",
    "Select": "Chọn",
    "Create": "Tạo",
    "Apply": "Áp dụng",
    "SelectImage": "Chọn hình ảnh",
    "Change": "Thay đổi",
    "Remove": "Loại bỏ",
    "Cancel": "Cancel",
    "Upload": "Upload",
    "Reload": "Tải lại"
  },
  "Placeholder": {
    "Enter": "Enter {{value}}",
    "Select": "Select {{value}}"
  },
  "Services": {
    "ChangeStatusSuccess": "Thay đổi trạng thái thành công",
    "DeleteConfirm": "Bạn có chắc muốn xóa {{title}} này?",
    "DeleteConfirmDefault": "Bạn có chắc muốn xóa?",
    "DeleteSuccess": "Xóa {{title}} thành công",
    "DeleteSuccessDefault": "Xóa thành công",
    "CreateNewSuccess": "Tạo mới {{title}} thành công",
    "CreateNewSuccessDefault": "Tạo mới thành công",
    "UpdateSuccess": "Cập nhật {{title}} thành công",
    "UpdateSuccessDefault": "Cập nhật thành công",
    "CancelConfirm": "Bạn có chắc muốn hủy {{title}} này?",
    "CancelConfirmDefault": "Bạn có chắc muốn hủy?",
    "CancelSuccess": "Hủy {{title}} thành công",
    "CancelSuccessDefault": "Hủy thành công",
    "NoData": "Không có bản ghi"
  },
  "Button": {
    "SignIn": "Sign in",
    "Confirm": "Confirm",
    "Cancel": "Cancel",
    "Logout": "Logout",
    "Delete": "Delete",
    "Back": "Go back",
    "HomePage": "Home page"
  },
  "Settings": {
    "Title": "Settings",
    "Category": "Category",
    "Profile": "Profile",
    "PointOfSale": "Point of sale",
    "Product": "Product",
    "Food": "Food",
    "Menu": "Menu",
    "Kitchen": "Kitchen"
  },
  "Profile": {
    "Title": "Thông tin cá nhân",
    "GeneralInformation": "Thông tin chung",
    "LoginDetail": "Thông tin đăng nhập",
    "Name": "Tên",
    "Email": "Email",
    "PhoneNumber": "Số điện thoại",
    "UserName": "Tên người dùng",
    "CurrentPassword": "Mật khẩu hiện tại",
    "Password": "Mật khẩu",
    "ConfirmPassword": "Xác nhận mật khẩu"
  },
  "Nav": {
    "Settings": "Settings",
    "Guest": "Guest"
  },
  "Bank": {
    "Title": "Bank",
    "SearchPlaceholder": "Find by bank name",
    "NewButton": "Add a new bank",
    "Name": "Name",
    "DisplayName": "Display name",
    "Description": "Description",
    "IsActive": "Status",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewBank": "New bank",
    "BankDetail": "Bank detail",
    "Reset": "Reset",
    "Add": "Add",
    "Update": "Update",
    "AddNewBank": "Add new bank",
    "BankUpdate": "Bank update",
    "EnterBankName": "Enter bank name",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name"
  },
  "Cook": {
    "Title": "Cook",
    "SearchPlaceholder": "Find by cook name",
    "NewButton": "Add a new cook",
    "Name": "Name",
    "Code": "Code",
    "DisplayName": "Display name",
    "Description": "Description",
    "IsActive": "Status",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewCook": "New cook",
    "CookDetail": "Cook detail",
    "Reset": "Reset",
    "Add": "Add",
    "Update": "Update",
    "AddNewCook": "Add new cook",
    "CookUpdate": "Cook update",
    "EnterCookName": "Enter cook name",
    "EnterCookCode": "Enter cook code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name"
  },
  "Material": {
    "Title": "Material",
    "SearchPlaceholder": "Find by material name",
    "NewButton": "Add a new material",
    "Name": "Name",
    "Code": "Code",
    "DisplayName": "Display name",
    "Description": "Description",
    "Type": "type",
    "IsInventory": "Is inventory",
    "IsActive": "Status",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewMaterial": "New material",
    "MaterialDetail": "Material detail",
    "Reset": "Reset",
    "Add": "Add",
    "Update": "Update",
    "AddNewMaterial": "Add new material",
    "MaterialUpdate": "Material update",
    "EnterMaterialName": "Enter material name",
    "EnterMaterialCode": "Enter material code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name"
  },
  "FoodCategory": {
    "Title": "Food category",
    "SearchPlaceholder": "Find by food category name",
    "NewButton": "Add a new food category",
    "Name": "Name",
    "Code": "Code",
    "DisplayName": "Display name",
    "Description": "Description",
    "IsActive": "Status",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewFoodCategory": "New food category",
    "FoodCategoryDetail": "Food category detail",
    "Reset": "Reset",
    "Add": "Add",
    "Update": "Update",
    "AddNewFoodCategory": "Add new food category",
    "FoodCategoryUpdate": "Food category update",
    "EnterFoodCategoryName": "Enter food category name",
    "EnterFoodCategoryCode": "Enter food category code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name"
  },
  "ProductCategory": {
    "Title": "Product category",
    "SearchPlaceholder": "Find by product category name",
    "NewButton": "Add a new product category",
    "Name": "Name",
    "DisplayName": "Display name",
    "Description": "Description",
    "IsActive": "Status",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewProductCategory": "New product category",
    "ProductCategoryDetail": "Product category detail",
    "Reset": "Reset",
    "Add": "Add",
    "Update": "Update",
    "AddNewProductCategory": "Add new product category",
    "ProductCategoryUpdate": "Product category update",
    "EnterProductCategoryName": "Enter product category name",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name"
  },
  "Guest": {
    "Title": "Khách hàng",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Add a new guest",
    "FirstName": "Tên",
    "LastName": "Họ",
    "Name": "Tên",
    "Code": "Code",
    "DisplayName": "Display name",
    "MobilePhone": "Số điện thoại",
    "IsActive": "Status",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewGuest": "Khách hàng mới",
    "GuestDetail": "Chi tiết khách hàng",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Update",
    "AddNewGuest": "Add new guest",
    "GuestUpdate": "Guest update",
    "Option": "Tùy chọn",
    "TotalGuest": "Tổng khách hàng",
    "TotalRetailGuest": "Khách lẻ",
    "TotalWholesaleGuest": "Khách sỉ",
    "Display": "Hiển thị",
    "ShortInfo": "Thông tin rút gọn",
    "FullInfo": "Thông tin đầy đủ",
    "FullInfoSub": "Gồm \"Ngày mua hàng cuối\" và \"Tổng mua\"",
    "FilterByGuest": "Lọc theo khách hàng",
    "FilterByStatus": "Lọc theo trạng thái",
    "All": "Tất cả",
    "Wholesale": "Sỉ",
    "Retail": "Lẻ",
    "Active": "Hoạt động",
    "Lock": "Khóa",
    "BlackList": "Danh sách đen",
    "LastOrder": "Đơn hàng cuối",
    "TotalBuy": "Tổng mua",
    "AddToBlackList": "Thêm vào danh sách đen",
    "RemoveFromBlackList": "Xóa khỏi danh sách đen",
    "GeneralInfo": "Thông tin chung",
    "UserName": "Username",
    "Password": "Mật khẩu",
    "ConfirmPassword":"Xác nhận mật khẩu",
    "MiddleName": "Tên đệm",
    "AliasName": "Alias name",
    "DateOfBirth": "Ngày sinh",
    "Email": "Email",
    "Address": "Địa chỉ",
    "Bio": "Bio",
    "UserInfo": "Thông tin tài khoản",
    "ProfileInfo": "Thông tin cá nhân"
  },
  "PointOfSale": {
    "Title": "point of sale",
    "SearchPlaceholder": "Find by name",
    "List": "List",
    "Information": "Information",
    "Tables": "Tables",
    "Table": "Table",
    "NewButton": "Add a new point of sale",
    "Name": "Name",
    "Code": "Code",
    "Hotline": "Hotline",
    "Address": "Address",
    "Floor": "Floor",
    "Area": "Area",
    "Row": "Row",
    "Bay": "Bay",
    "Level": "Level",
    "Pos": "Pos",
    "ChangeStatus": "Change status",
    "Description": "Description",
    "DisplayName": "Display name",
    "MobilePhone": "Mobile phone",
    "IsActive": "Status",
    "Action": "Action",
    "Page": "Page",
    "NewPointOfSale": "New point of sale",
    "PointOfSaleDetail": "Point of sale detail",
    "Reset": "Reset",
    "Add": "Add",
    "Save": "Save",
    "AddNewTables": "Add tables",
    "UpdateTable": "Table update",
    "Update": "Update",
    "AddNewPointOfSale": "Add new point of sale",
    "PointOfSaleUpdate": "Point of sale update",
    "EnterPointOfSaleName": "Enter point of sale name",
    "EnterMobilePhone": "Enter mobile phone",
    "EnterHotline": "Enter hotline",
    "EnterAddress": "Enter address",
    "EnterFloor": "Enter floor",
    "EnterArea": "Enter area",
    "EnterBay": "Enter bay",
    "EnterLevel": "Enter level",
    "EnterRow": "Enter row",
    "EnterPos": "Enter pos",
    "EnterDescription": "Enter description",
    "EnterCode": "Enter code",
    "EnterName": "Enter name",
    "EnterMenu": "Enter menu",
    "EnterDisplayName": "Enter display name",
    "EnterStore": "Enter store",
    "Store": "Store",
    "Source": "Source"
  },
  "Product": {
    "Title": "Product",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewProduct": "Sản phẩm mới",
    "ProductDetail": "Chi tiết sản phẩm",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "AddNewProduct": "Thêm sản phẩm mới",
    "UpdateProduct": "Cập nhật sản phẩm",
    "EnterProductName": "Enter product name",
    "EnterProductCode": "Enter product code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name",
    "OfficialName": "Official name",
    "VatName": "VAT name",
    "Note": "Ghi chú",
    "Region": "Region",
    "Tags": "Tags",
    "Weight": "Weight",
    "ConvertedQuantity": "Converted quantity",
    "GeneralInfo": "Thông tin chung",
    "IsHotProduct": "Is hot product",
    "IsNewProduct": "Is new product",
    "IsRecommendedProduct": "Is recommended product",
    "Wholesale": "Giá sỉ",
    "Retail": "Giá lẻ",
    "SelectProduct": "Chọn sản phẩm",
    "SalePrice": "Sale price",
    "ListPrice": "List price",
    "Quantity": "Số lượng",
    "Commission": "Commision",
    "ShortDescription": "Short description",
    "IsPromotionalProduct": "Is promotional product",
    "IsOutletProduct": "Is outlet product"
  },
  "Food": {
    "Title": "Food",
    "Foods": "Food",
    "Recipes": "Recipes",
    "FoodCategory": "Category",
    "FoodDetail": "Food detail",
    "EnterFoodCategory": "Enter food category",
    "EnterCook": "Enter cook",
    "EnterProduct": "Enter product",
    "FoodUpdate": "Food update",
    "Cook": "Cook",
    "Product": "Product",
    "EnterDisplayName": "Enter display name",
    "EnterFoodCode": "Enter food code",
    "EnterFoodName": "Enter food name",
    "EnterUnit": "Enter unit",
    "Name": "Name",
    "Code": "Code",
    "DisplayName": "Display name",
    "NewFood": "New food",
    "FoodPrice": "Food price",
    "ExtraPrice": "Extra price",
    "EditCategory": "Edit category",
    "EditList": "Edit list",
    "SelectCategoryViewProduct": "Select a category to see a list of products",
    "AddProduct": "Add product",
    "NoProduct": "There are no products in this category, please create a new one",
    "Prices": "Prices",
    "Price": "Price",
    "NewPrices": "New prices",
    "PriceList": "Price list",
    "SizeExist": "Size has been setup price"
  },
  "Menu": {
    "Title": "Menu",
    "Name": "Name",
    "Code": "Code",
    "SearchPlaceholder": "Find by menu name",
    "MenuSearchPlaceholder": "Search by name or code",
    "Description": "Description",
    "NewButton": "Add new",
    "DisplayName": "Display name",
    "IsActive": "Status",
    "Action": "Action",
    "Page": "Page",
    "Reset": "Reset",
    "Add": "Add",
    "Default": "Default",
    "EffectiveDate": "Effective date",
    "EnterDescription": "Enter description",
    "EnterCode": "Enter code",
    "EnterName": "Enter name",
    "EnterDisplayName": "Enter display name",
    "AddNewMenuItems": "Add new menu items",
    "UpdateMenuItems": "Update menu items",
    "FoodCategory": "Food category",
    "SelectedItems": "Selected items",
    "FoodId": "Food Id",
    "Price": "Price",
    "ListSelected": "List selected",
    "AddNewMenu": "Add new menu",
    "MenuUpdate": "Update menu",
    "MenuDetail": "Menu detail",
    "Sections": "Sections",
    "Items": "Items",
    "AddNewMenuSection": "Add new menu section"
  },
  "Unit": {
    "Title": "unit",
    "SearchPlaceholder": "Find by name",
    "NewButton": "Add a new unit",
    "Name": "Name",
    "Code": "Code",
    "DisplayName": "Display name",
    "Description": "Description",
    "UnitType": "Setup",
    "IsActive": "Status",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewUnit": "New unit",
    "UnitDetail": "Unit detail",
    "Reset": "Reset",
    "Add": "Add",
    "Update": "Update",
    "Save": "Save",
    "AddNewUnit": "Add new unit",
    "UnitUpdate": "Unit update",
    "EnterUnitName": "Enter name",
    "EnterUnitCode": "Enter code",
    "EnterDisplayName": "Enter display name",
    "EnterDescription": "Enter description"
  },
  "Kitchen": {
    "Title": "Kitchen",
    "List": "List",
    "Information": "Information",
    "Settings": "Settings",
    "Setting": "Setting",
    "NewButton": "Add a new kitchen",
    "Name": "Name",
    "Code": "Code",
    "ChangeStatus": "Change status",
    "Description": "Description",
    "DisplayName": "Display name",
    "IsActive": "Status",
    "Action": "Action",
    "Page": "Page",
    "NewKitchen": "New kitchen",
    "KitchenDetail": "Kitchen detail",
    "Reset": "Reset",
    "Add": "Add",
    "AddNewSettings": "Add settings",
    "UpdateTable": "Table update",
    "Update": "Update",
    "AddNewKitchen": "Add new kitchen",
    "KitchenUpdate": "Kitchen update",
    "EnterKitchenName": "Enter kitchen name",
    "EnterDescription": "Enter description",
    "EnterCode": "Enter code",
    "EnterName": "Enter name",
    "EnterMenu": "Enter menu",
    "EnterDisplayName": "Enter display name",
    "EnterCook": "Enter cook name",
    "EnterPointOfSale": "Enter point of sale name",
    "EnterNote": "Enter note",
    "Note": "Note"
  },
  "RecipeType": {
    "Title": "RecipeType",
    "SearchPlaceholder": "Find by recipe type name",
    "NewButton": "Add a new recipe type",
    "Name": "Name",
    "Code": "Code",
    "DisplayName": "Display name",
    "Description": "Description",
    "IsOptional": "Is optional",
    "IsMany": "Is many",
    "IsMultiSelect": "Is multi select",
    "IsActive": "Status",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewRecipeType": "New recipe type",
    "RecipeTypeDetail": "Recipe type detail",
    "Reset": "Reset",
    "Add": "Add",
    "Update": "Update",
    "AddNewRecipeType": "Add new recipe type",
    "RecipeTypeUpdate": "Recipe type update",
    "EnterRecipeTypeName": "Enter recipe type name",
    "EnterRecipeTypeCode": "Enter recipe type code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name"
  },
  "Size": {
    "Title": "Size",
    "SearchPlaceholder": "Find by size name",
    "NewButton": "Add a new size",
    "Name": "Name",
    "Code": "Code",
    "DisplayName": "Display name",
    "Description": "Description",
    "IsActive": "Status",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewSize": "New size",
    "SizeDetail": "Size detail",
    "Reset": "Reset",
    "Add": "Add",
    "Update": "Update",
    "AddNewSize": "Add new size",
    "SizeUpdate": "Size update",
    "EnterSizeName": "Enter size name",
    "EnterSizeCode": "Enter size code",
    "EnterNote": "Enter note",
    "EnterDisplayName": "Enter display name"
  },
  "Sale": {
    "Title": "Sale",
    "NewOrder": "New order",
    "NewOrderTicket": "Order ticket",
    "RetailOrder": "Retail order",
    "WholesaleOrder": "Wholesale order",
    "SalesManagement": "Sales management",
    "TableManaGeMent": "Table management",
    "OrderToday": "Đơn hàng hôm nay",
    "PurchaseOrder": "Đơn đặt hàng"
  },
  "Recipe": {
    "Title": "Recipe",
    "SearchPlaceholder": "Find by recipe name",
    "NewButton": "Add a new recipe",
    "Name": "Name",
    "Code": "Code",
    "EndProduct": "End product",
    "Food": "Food",
    "Size": "Size",
    "EndQuantity": "End quantity",
    "TotalCost": "Total cost",
    "DisplayName": "Display name",
    "Description": "Description",
    "IsOptional": "Is optional",
    "IsMany": "Is many",
    "IsMultiSelect": "Is multi select",
    "IsActive": "Status",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewRecipe": "New recipe",
    "RecipeDetail": "Recipe detail",
    "Reset": "Reset",
    "Add": "Add",
    "Update": "Update",
    "AddNewRecipe": "Add new recipe",
    "RecipeUpdate": "Recipe update",
    "EnterRecipeName": "Enter recipe name",
    "EnterRecipeCode": "Enter recipe code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name"
  },
  "Brand": {
    "Title": "brand",
    "SearchPlaceholder": "Find by brand name",
    "NewButton": "Add a new brand",
    "Name": "Name",
    "Code": "Code",
    "EndProduct": "End product",
    "Food": "Food",
    "Size": "Size",
    "EndQuantity": "End quantity",
    "TotalCost": "Total cost",
    "DisplayName": "Display name",
    "Description": "Description",
    "IsOptional": "Is optional",
    "IsMany": "Is many",
    "IsMultiSelect": "Is multi select",
    "IsActive": "Status",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewBrand": "New brand",
    "BrandDetail": "Brand detail",
    "Reset": "Reset",
    "Add": "Add",
    "Update": "Update",
    "Save": "Save",
    "AddNewBrand": "Add new brand",
    "UpdateBrand": "Brand update",
    "EnterBrandName": "Enter brand name",
    "EnterBrandCode": "Enter brand code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name"
  },
  "Catalog": {
    "Title": "catalog",
    "SearchPlaceholder": "Search by catalog name",
    "NewButton": "New",
    "Stt": "Stt",
    "Name": "Name",
    "Code": "Code",
    "DisplayName": "Display name",
    "Description": "Description",
    "IsActive": "Is active",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewCatalog": "New catalog",
    "CatalogDetail": "Catalog detail",
    "Reset": "Reset",
    "Add": "Add",
    "Update": "Update",
    "Save": "Save",
    "AddNewCatalog": "Add new catalog",
    "UpdateCatalog": "Update catalog",
    "EnterCatalogName": "Enter catalog name",
    "EnterCatalogCode": "Enter catalog code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name",
    "SelectCatalog": "Select catalog"
  },
  "Distribution": {
    "Title": "distribution",
    "SearchPlaceholder": "Search by distribution name",
    "NewButton": "New",
    "Name": "Name",
    "Code": "Code",
    "DisplayName": "Display name",
    "Description": "Description",
    "IsActive": "Is active",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewDistribution": "New distribution",
    "DistributionDetail": "Distribution detail",
    "Reset": "Reset",
    "Add": "Add",
    "Update": "Update",
    "Save": "Save",
    "AddNewDistribution": "Add new distribution",
    "UpdateDistribution": "Update distribution",
    "EnterDistributionName": "Enter distribution name",
    "EnterDistributionCode": "Enter distribution code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name"
  },
  "MadeInCountry": {
    "Title": "made in country",
    "SearchPlaceholder": "Search by made in country name",
    "NewButton": "New",
    "Name": "Name",
    "Code": "Code",
    "DisplayName": "Display name",
    "Description": "Description",
    "IsActive": "Is active",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewMadeInCountry": "New made in country",
    "MadeInCountryDetail": "Made in country detail",
    "Reset": "Reset",
    "Add": "Add",
    "Update": "Update",
    "Save": "Save",
    "AddNewMadeInCountry": "Add new made in country",
    "UpdateMadeInCountry": "Update made in country",
    "EnterMadeInCountryName": "Enter made in country name",
    "EnterMadeInCountryCode": "Enter made in country code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name"
  },
  "Post": {
    "Post": "Post",
    "Title": "Title",
    "SearchPlaceholder": "Search by title",
    "NewButton": "New",
    "Name": "Name",
    "Code": "Code",
    "DisplayName": "Display name",
    "Description": "Description",
    "IsActive": "Is active",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewPost": "New post",
    "PostDetail": "Post detail",
    "Reset": "Reset",
    "Add": "Add",
    "Update": "Update",
    "Save": "Save",
    "AddNewPost": "Add new post",
    "UpdatePost": "Update post",
    "EnterPostName": "Enter post name",
    "EnterPostCode": "Enter post code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name",
    "Content": "Content",
    "Author": "Author",
    "HashTags": "Hashtags"
  },
  "ProductAge": {
    "Title": "product age",
    "SearchPlaceholder": "Search by product age name",
    "NewButton": "New",
    "Name": "Name",
    "Code": "Code",
    "DisplayName": "Display name",
    "Description": "Description",
    "IsActive": "Is active",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewProductAge": "New product age",
    "ProductAgeDetail": "Product age detail",
    "Reset": "Reset",
    "Add": "Add",
    "Update": "Update",
    "Save": "Save",
    "AddNewProductAge": "Add new product age",
    "UpdateProductAge": "Update product age",
    "EnterProductAgeName": "Enter product age name",
    "EnterProductAgeCode": "Enter product age code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name",
    "Content": "Content"
  },
  "Specification": {
    "Title": "specification"
  },
  "ConcentrationUnit": {
    "Title": "concentration unit",
    "Concentration": "Concentration"
  },
  "CapacityUnit": {
    "Title": "capacity unit",
    "Capacity": "Capacity"
  },
  "BusinessType": {
    "Title": "business type",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewBusinessType": "business type mới",
    "BusinessTypeDetail": "business type chi tiết",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "AddNewBusinessType": "Add new business type",
    "UpdateBusinessType": "Cập nhật business type",
    "EnterBusinessTypeName": "Enter business type name",
    "EnterBusinessTypeCode": "Enter business type code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name",
    "Content": "Content"
  },
  "ProductType": {
    "Title": "product type",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewProductType": "product type mới",
    "ProductTypeDetail": "product type chi tiết",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "AddNewProductType": "Add new product type",
    "UpdateProductType": "Cập nhật product type",
    "EnterProductTypeName": "Enter product type name",
    "EnterProductTypeCode": "Enter product type code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name",
    "Content": "Content"
  },
  "Price": {
    "Title": "price",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewPrice": "price mới",
    "PriceDetail": "price chi tiết",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "AddNewPrice": "Add new price",
    "UpdatePrice": "Cập nhật price",
    "EnterPriceName": "Enter price name",
    "EnterPriceCode": "Enter price code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name",
    "Content": "Content",
    "PriceType": "Price type",
    "ProductPrice": "Product price",
    "OriginalPrice": "Original price"
  },
  "PurchaseOrder": {
    "Title": "Đơn hàng",
    "SearchPlaceholder": "Tìm theo mã đơn hàng",
    "NewButton": "Mới",
    "Stt": "Stt",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Status": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewPurchaseOrder": "Đơn hàng mới",
    "PurchaseOrderDetail": "Chi tiết đơn hàng",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "UpdatePurchaseOrder": "Cập nhật đơn hàng",
    "Guest": "Khách hàng",
    "Employee": "Nhân viên",
    "IsDeliveryAtStore": "Is delivery at store?",
    "IsOnline": "Is online?",
    "Channel": "Channel",
    "Note": "Ghi chú",
    "PurchaseOrderItems": "Purchase order items",
    "NoProduct": "Chưa có sản phẩm",
    "Type": "Loại",
    "Qty": "SL",
    "OrderCode": "Mã ĐH",
    "CreatedAt": "Tạo lúc",
    "OrderInfo": "Thông tin bán hàng",
    "Subtotal": "Tổng tạm tính",
    "OrderTotal": "Tổng đơn hàng",
    "PointOfSale": "Điểm bán hàng",
    "CreatedDate": "Ngày tạo",
    "CreateOrder": "Tạo đơn hàng",
    "ShippingAgent": "Shipping agent",
    "DeliveryAddress": "Địa chỉ giao hàng",
    "DeliveryDate": "Ngày giao hàng",
    "PaymentMethod": "Phương thức thanh toán",
    "ShippingFee": "Phí giao hàng",
    "ShippingFeeNote": "Ghi chú giao hàng",
    "DiscountAmount": "Giảm trừ",
    "DiscountNote": "Ghi chú giảm trừ",
    "TaxAmount": "Thuế phí",
    "TaxNote": "Ghi chú thuế phí",
    "DiscountLargerThanTotal": "Giảm trừ không thể lớn hơn tổng đơn hàng"
  },
  "PaymentMethod": {
    "Cash": "Tiền mặt",
    "Cod": "Cash on delivery",
    "BankTransfer": "Chuyển khoản",
    "Debt": "Công nợ",
    "CreditCard": "Credit card",
    "VnPay": "VNPAY",
    "Momo": "MOMO",
    "BalanceAccount": "Tài khoản dư",
    "COP": "Nhờ thu - C.O.P",
    "OpenAccount": "Ghi sổ - Open account",
    "LC": "Tính dụng chứng từ - L.C",
    "Paypal": "Paypal",
    "Other": "Khác"
  },
  "Language": {
    "Title": "Ngôn ngữ",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "ShortName": "Tên rút gọn",
    "NumCode": "Num code",
    "IsActive": "Trạng thái",
    "NewLanguage": "Ngôn ngữ mới",
    "UpdateLanguage": "Cập nhật ngôn ngữ",
    "Add": "Thêm mới"
  },
  "Category": {
    "Title": "Danh mục",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "ParentCategory": "Danh mục cha",
    "Language": "Ngôn ngữ",
    "IsActive": "Trạng thái",
    "NewCategory": "Danh mục mới",
    "UpdateCategory": "Cập nhật danh mục",
    "Add": "Thêm mới"
  },
  "Slug": {
    "Title": "Slug",
    "SearchPlaceholder": "Tìm theo tên",
    "FullUri": "FullUri",
    "SlugContent": "Slug content",
    "Prefix": "Prefix",
    "TargetType": "Target type",
    "Target": "Target",
    "Language": "Language",
    "IsActive": "Trạng thái",
    "NewSlug": "Slug mới",
    "UpdateSlug": "Cập nhật slug",
    "Add": "Thêm mới"
  },
  "User": {
    "Title": "User",
    "SearchPlaceholder": "Tìm theo username",
    "Add": "Thêm mới",
    "FirstName": "Tên",
    "MiddleName": "Tên đệm",
    "LastName": "Họ",
    "aliasName": "Alias name",
    "Bio": "Bio",
    "Email": "Email",
    "DateOfBirth": "Ngày sinh",
    "PhoneNumber": "Số điện thoại",
    "Address": "Địa chỉ",
    "IdNumber": "Id number",
    "Avatar": "Avatar",
    "Gender": "Giới tính",
    "Website": "Website",
    "UserName": "Username",
    "Password": "Mật khẩu",
    "ConfirmPassword": "Xác nhận mật khẩu",
    "IsActive": "Trạng thái",
    "NewUser": "Người dùng mới",
    "UpdateUser": "Cập nhật người dùng",
    "Male": "Name",
    "Female": "Nữ",
    "Other": "Khác"
  },
  "Image": {
    "SelectImage": "Chọn hình ảnh",
    "UploadSuccessfully": "Tải lên thành công"
  },
  "Subject": {
    "Title": "Subject",
    "SearchPlaceholder": "Tìm theo mã hoặc tên",
    "Stt": "Stt",
    "Code": "Code",
    "Name": "Name",
    "DisplayName": "Display name",
    "IsActive": "Trạng thái",
    "NewSubject": "Subject mới",
    "UpdateSubject": "Cập nhật subject",
    "NewButton": "Thêm mới"
  },
  "Banner": {
    "Banner": "Banner",
    "Title": "Title",
    "SearchPlaceholder": "Tìm theo title",
    "Stt": "Stt",
    "SubTitle": "Subtitle",
    "BannerType": "Banner type",
    "Article": "Article",
    "Language": "Language",
    "Description": "Description",
    "IsActive": "Trạng thái",
    "NewBanner": "Banner mới",
    "UpdateBanner": "Cập nhật Banner",
    "NewButton": "Thêm mới"
  },
  "BannerType": {
    "ArticleBanner": "Article banner",
    "HeroBanner": "Hero banner",
    "SideBanner": "Side banner",
    "Other": "Other"
  },
  "SaleSetting": {
    "Title": "Sale setting",
    "SearchPlaceholder": "Tìm theo mã",
    "Stt": "Stt",
    "Code": "Code",
    "Version": "Version",
    "EffectiveDate": "Ngày hiệu lực",
    "ExpirationDate": "Expiration date",
    "TaxRate": "Tax rate",
    "TaxMaxAmount": "Tax max amount",
    "TaxMinAmount": "Tax rate",
    "DiscountRate": "Discount rate",
    "DiscountMaxAmount": "Discount max amount",
    "DiscountMinAmount": "Discount min amount",
    "SaleSettingStatus": "Sale setting status",
    "IsActive": "Trạng thái",
    "NewSaleSetting": "Sale setting mới",
    "UpdateSaleSetting": "Cập nhật sale setting",
    "NewButton": "Thêm mới"
  },
  "SaleSettingStatus": {
    "Draft": "Draft",
    "Activated": "Activated",
    "Deactivated": "Deactivated"
  }
}