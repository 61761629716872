{
  "Auth": {
    "Common": {
      "Password": "mật khẩu"
    },
    "Errors": {
      "UsernameOrPassword": "Tên đăng nhập hoặc mật khẩu không đúng"
    },
    "Login": {
      "Title": "Đăng nhập",
      "EmailPlaceholder": "Nhập email",
      "PasswordPlaceholder": "Nhập mật khẩu",
      "Heading": "Trải nghiệm <br /> cùng <span class='text-3xl'> Whiteshark retail </span>"
    },
    "Register": {
      "Title": "đăng kí"
    },
    "ForgotPassword": {
      "Title": "quên mật khẩu"
    }
  },
  "Confirmation": {
    "SaveChange": "Bạn có muốn lưu thay đổi chưa được lưu không?"
  },
  "NotFound": {
    "PageNotFound": "Không tìm thấy thông tin hoặc trang này",
    "NotAuth": "Bạn không có quyền truy cập trang này. Liên hệ admin để được trợ giúp"
  },
  "Status": {
    "Active": "Hoạt động",
    "Inactive": "Khóa"
  },
  "Errors": {
    "Required": "Trường này là bắt buộc",
    "InvalidEmail": "Email không hợp lệ",
    "InvalidPhone": "Số điện thoại không hợp lệ",
    "MustMatch": "{{control}} phải trùng với {{matchingControl}}",
    "MinLength": "Tối thiểu {{minLength}} ký tự",
    "ExistingCode": "Mã này đã được sử dụng",
    "InvalidAddress": "Địa chỉ phải được chọn từ google map",
    "InvalidValue": "Giá trị không đúng",
    "AutoComplete": "Trường này phải chọn từ danh sách",
    "Whitespace": "Không thể chỉ chứa khoảng trắng"
  },
  "Pagination": {
    "Display": "Hiển thị",
    "Page": "Trang",
    "TotalRecords": "Tổng <span class='font-medium text-accent'>{{value}}</span> bản ghi"
  },
  "Action": {
    "Title": "Thao tác",
    "Lock": "Khóa",
    "Unlock": "Mở khóa",
    "Delete": "Xóa",
    "Edit": "Sửa",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Reset": "Đặt lại",
    "Save": "Lưu",
    "AddPrices": "Thêm giá mới",
    "Select": "Chọn",
    "Create": "Tạo",
    "Apply": "Áp dụng",
    "SelectImage": "Chọn hình ảnh",
    "Change": "Thay đổi",
    "Remove": "Loại bỏ",
    "Cancel": "Hủy",
    "Upload": "Tải lên",
    "Reload": "Tải lại"
  },
  "Placeholder": {
    "Enter": "Nhập {{value}}",
    "Select": "Chọn {{value}}"
  },
  "Services": {
    "ChangeStatusSuccess": "Thay đổi trạng thái thành công",
    "DeleteConfirm": "Bạn có chắc muốn xóa {{title}} này?",
    "DeleteConfirmDefault": "Bạn có chắc muốn xóa?",
    "DeleteSuccess": "Xóa {{title}} thành công",
    "DeleteSuccessDefault": "Xóa thành công",
    "CreateNewSuccess": "Tạo mới {{title}} thành công",
    "CreateNewSuccessDefault": "Tạo mới thành công",
    "UpdateSuccess": "Cập nhật {{title}} thành công",
    "UpdateSuccessDefault": "Cập nhật thành công",
    "CancelConfirm": "Bạn có chắc muốn hủy {{title}} này?",
    "CancelConfirmDefault": "Bạn có chắc muốn hủy?",
    "CancelSuccess": "Hủy {{title}} thành công",
    "CancelSuccessDefault": "Hủy thành công",
    "NoData": "Không có bản ghi"
  },
  "Button": {
    "SignIn": "Đăng nhập",
    "Confirm": "Xác nhận",
    "Cancel": "Hủy",
    "Delete": "Xóa",
    "Logout": "Đăng xuất",
    "Back": "Quay lại",
    "HomePage": "Trang chủ"
  },
  "Settings": {
    "Title": "Thiết lập",
    "Category": "Danh mục",
    "Profile": "Thông tin cá nhân",
    "PointOfSale": "Điểm bán hàng",
    "Product": "Sản phẩm",
    "Food": "Món ăn",
    "Menu": "Thực đơn",
    "Kitchen": "Bếp"
  },
  "Profile": {
    "Title": "Thông tin cá nhân",
    "GeneralInformation": "Thông tin chung",
    "LoginDetail": "Thông tin đăng nhập",
    "Name": "Tên",
    "Email": "Email",
    "PhoneNumber": "Số điện thoại",
    "UserName": "Tên người dùng",
    "CurrentPassword": "Mật khẩu hiện tại",
    "Password": "Mật khẩu",
    "ConfirmPassword": "Xác nhận mật khẩu"
  },
  "Nav": {
    "Settings": "Thiết lập",
    "Guest": "Guest"
  },
  "Bank": {
    "Title": "Ngân hàng",
    "SearchPlaceholder": "Tìm theo tên hoặc mã ngân hàng",
    "NewButton": "Thêm mới ngân hàng",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Thao tác",
    "Page": "Trang",
    "TotalRecords": "Tổng {{value}} bản ghi",
    "NewBank": "Ngân hàng mới",
    "BankDetail": "Chi tiết ngân hàng",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "AddNewBank": "Thêm mới ngân hàng",
    "BankUpdate": "Cập nhật ngân hàng",
    "EnterBankName": "Nhập tên ngân hàng",
    "EnterDescription": "Nhập mô tả",
    "EnterDisplayName": "Nhập tên hiển thị"
  },
  "Cook": {
    "Title": "Chế biến",
    "SearchPlaceholder": "Tìm theo tên hoặc mã danh mục chế biến",
    "NewButton": "Thêm mới danh mục chế biến",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Thao tác",
    "Page": "Trang",
    "TotalRecords": "Tổng {{value}} bản ghi",
    "NewCook": "Danh mục chế biến mới",
    "CookDetail": "Chi tiết danh mục chế biến",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "AddNewCook": "Thêm mới danh mục chế biến",
    "CookUpdate": "Cập nhật danh mục chế biến",
    "EnterCookName": "Nhập tên danh mục chế biến",
    "EnterCookCode": "Nhập mã danh mục chế biến",
    "EnterDescription": "Nhập mô tả",
    "EnterDisplayName": "Nhập tên hiển thị"
  },
  "Material": {
    "Title": "Nguyên liệu",
    "SearchPlaceholder": "Tìm theo tên hoặc mã nguyên liệu",
    "NewButton": "Thêm mới nguyên liệu",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "Type": "Loại",
    "IsInventory": "Là hàng tồn kho",
    "IsActive": "Trạng thái",
    "Action": "Thao tác",
    "Page": "Trang",
    "TotalRecords": "Tổng {{value}} bản ghi",
    "NewMaterial": "Nguyên liệu mới",
    "MaterialDetail": "Chi tiết nguyên liệu",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "AddNewMaterial": "Thêm mới nguyên liệu",
    "MaterialUpdate": "Cập nhật nguyên liệu",
    "EnterMaterialName": "Nhập tên nguyên liệu",
    "EnterMaterialCode": "Nhập mã nguyên liệu",
    "EnterDescription": "Nhập mô tả",
    "EnterDisplayName": "Nhập tên hiển thị"
  },
  "FoodCategory": {
    "Title": "Món ăn",
    "SearchPlaceholder": "Tìm theo tên hoặc mã món ăn",
    "NewButton": "Thêm mới món ăn",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Thao tác",
    "Page": "Trang",
    "TotalRecords": "Tổng {{value}} bản ghi",
    "NewFoodCategory": "Thêm mới món ăn",
    "FoodCategoryDetail": "Chi tiết món ăn",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "AddNewFoodCategory": "Thêm mới món ăn",
    "FoodCategoryUpdate": "Cập nhật món ăn",
    "EnterFoodCategoryName": "Nhập tên món ăn",
    "EnterFoodCategoryCode": "Nhập mã món ăn",
    "EnterDescription": "Nhập mô tả",
    "EnterDisplayName": "Nhập tên hiển thị"
  },
  "ProductCategory": {
    "Title": "Danh mục sản phẩm",
    "SearchPlaceholder": "Tìm theo tên hoặc mã danh mục sản phẩm",
    "NewButton": "Thêm mới danh mục sản phẩm",
    "Name": "Tên",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Thao tác",
    "Page": "Trang",
    "TotalRecords": "Tổng {{value}} bản ghi",
    "NewProductCategory": "Thêm mới danh mục sản phẩm",
    "ProductCategoryDetail": "Chi tiết danh mục sản phẩm",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "AddNewProductCategory": "Thêm mới danh mục sản phẩm",
    "ProductCategoryUpdate": "Cập nhật danh mục sản phẩm",
    "EnterProductCategoryName": "Nhập tên danh mục sản phẩm",
    "EnterDescription": "Nhập mô tả",
    "EnterDisplayName": "Nhập tên hiển thị"
  },
  "Guest": {
    "Title": "Khách hàng",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Add a new guest",
    "FirstName": "Tên",
    "LastName": "Họ",
    "Name": "Tên",
    "Code": "Code",
    "DisplayName": "Display name",
    "MobilePhone": "Số điện thoại",
    "IsActive": "Status",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewGuest": "Khách hàng mới",
    "GuestDetail": "Chi tiết khách hàng",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Update",
    "AddNewGuest": "Add new guest",
    "GuestUpdate": "Guest update",
    "Option": "Tùy chọn",
    "TotalGuest": "Tổng khách hàng",
    "TotalRetailGuest": "Khách lẻ",
    "TotalWholesaleGuest": "Khách sỉ",
    "Display": "Hiển thị",
    "ShortInfo": "Thông tin rút gọn",
    "FullInfo": "Thông tin đầy đủ",
    "FullInfoSub": "Gồm \"Ngày mua hàng cuối\" và \"Tổng mua\"",
    "FilterByGuest": "Lọc theo khách hàng",
    "FilterByStatus": "Lọc theo trạng thái",
    "All": "Tất cả",
    "Wholesale": "Sỉ",
    "Retail": "Lẻ",
    "Active": "Hoạt động",
    "Lock": "Khóa",
    "BlackList": "Danh sách đen",
    "LastOrder": "Đơn hàng cuối",
    "TotalBuy": "Tổng mua",
    "AddToBlackList": "Thêm vào danh sách đen",
    "RemoveFromBlackList": "Xóa khỏi danh sách đen",
    "GeneralInfo": "Thông tin chung",
    "UserName": "Username",
    "Password": "Mật khẩu",
    "ConfirmPassword":"Xác nhận mật khẩu",
    "MiddleName": "Tên đệm",
    "AliasName": "Alias name",
    "DateOfBirth": "Ngày sinh",
    "Email": "Email",
    "Address": "Địa chỉ",
    "Bio": "Bio",
    "UserInfo": "Thông tin tài khoản",
    "ProfileInfo": "Thông tin cá nhân"
  },
  "PointOfSale": {
    "Title": "Điểm bán hàng",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "List": "Danh sách",
    "Information": "Thông tin",
    "Tables": "Bàn",
    "Table": "Bàn",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "Hotline": "Hotline",
    "Address": "Địa chỉ",
    "Floor": "Floor",
    "Area": "Area",
    "Row": "Row",
    "Bay": "Bay",
    "Level": "Level",
    "Pos": "Pos",
    "ChangeStatus": "Đổi trạng thái",
    "Description": "Mô tả",
    "DisplayName": "Tên hiển thị",
    "MobilePhone": "Số điện thoại",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Trang",
    "NewPointOfSale": "Điểm bán hàng mới",
    "PointOfSaleDetail": "Chi tiết điểm bán hàng",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Save": "Lưu",
    "AddNewTables": "Thêm bàn",
    "UpdateTable": "Cập nhật bàn",
    "Update": "Cập nhật",
    "AddNewPointOfSale": "Thêm điểm bán hàng mới",
    "PointOfSaleUpdate": "Cập nhật điểm bán hàng",
    "EnterPointOfSaleName": "Nhập tên điểm bán hàng",
    "EnterMobilePhone": "Nhập số điện thoại",
    "EnterHotline": "Nhập hotline",
    "EnterAddress": "Nhập địa chỉ",
    "EnterFloor": "Enter floor",
    "EnterArea": "Enter area",
    "EnterBay": "Enter bay",
    "EnterLevel": "Enter level",
    "EnterRow": "Enter row",
    "EnterPos": "Enter pos",
    "EnterDescription": "Nhập mô tả",
    "EnterCode": "Nhập mã",
    "EnterName": "Nhập tên",
    "EnterMenu": "Nhập thực đơn",
    "EnterDisplayName": "Nhập tên hiển thị",
    "EnterStore": "Nhập Cửa hàng",
    "Store": "Cửa hàng",
    "Source": "Source"
  },
  "Product": {
    "Title": "Sản phẩm",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewProduct": "Sản phẩm mới",
    "ProductDetail": "Chi tiết sản phẩm",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "AddNewProduct": "Thêm sản phẩm mới",
    "UpdateProduct": "Cập nhật sản phẩm",
    "EnterProductName": "Enter product name",
    "EnterProductCode": "Enter product code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name",
    "OfficialName": "Official name",
    "VatName": "VAT name",
    "Note": "Ghi chú",
    "Region": "Region",
    "Tags": "Tags",
    "Weight": "Weight",
    "ConvertedQuantity": "Converted quantity",
    "GeneralInfo": "Thông tin chung",
    "IsHotProduct": "Is hot product",
    "IsNewProduct": "Is new product",
    "IsRecommendedProduct": "Is recommended product",
    "Wholesale": "Giá sỉ",
    "Retail": "Giá lẻ",
    "SelectProduct": "Chọn sản phẩm",
    "SalePrice": "Sale price",
    "ListPrice": "List price",
    "Quantity": "Số lượng",
    "Commission": "Commision",
    "ShortDescription": "Short description",
    "IsPromotionalProduct": "Is promotional product",
    "IsOutletProduct": "Is outlet product"
  },
  "Food": {
    "Title": "Món ăn",
    "Foods": "Món ăn",
    "Recipes": "Công thức",
    "FoodCategory": "Danh mục",
    "FoodDetail": "Chi tiết món ăn",
    "EnterFoodCategory": "Nhập danh mục món ăn",
    "EnterCook": "Nhập danh mục chế biến",
    "EnterProduct": "Nhập sản phẩm",
    "FoodUpdate": "Cập nhật món ăn",
    "Cook": "Chế biến",
    "Product": "Sản phẩm",
    "EnterDisplayName": "Nhập tên hiển thị",
    "EnterFoodCode": "Nhập mã",
    "EnterFoodName": "Nhập tên",
    "EnterUnit": "Nhập đơn vị tính",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "NewFood": "món ăn mới",
    "FoodPrice": "Giá món ăn",
    "ExtraPrice": "Giá khác",
    "EditCategory": "Chỉnh sửa danh mục",
    "EditList": "Chỉnh sửa danh sách",
    "SelectCategoryViewProduct": "Chọn danh mục để xem danh sách sản phẩm",
    "AddProduct": "Thêm sản phẩm",
    "NoProduct": "Danh mục chưa có sản phẩm vui lòng tạo mới",
    "Prices": "Giá đã thêm",
    "Price": "Giá",
    "NewPrices": "Danh sách giá thêm mới",
    "PriceList": "Danh sách giá",
    "SizeExist": "Size đã thiết lập giá"
  },
  "Menu": {
    "Title": "Thực đơn",
    "Name": "Tên",
    "Code": "Mã",
    "SearchPlaceholder": "Tìm theo tên hoặc mã thực đơn",
    "MenuSearchPlaceholder": "Tìm theo tên hoặc mã hoặc mã",
    "Description": "Mô tả",
    "NewButton": "Thêm mới",
    "DisplayName": "Tên hiển thị",
    "IsActive": "Trạng thái",
    "Action": "Thao tác",
    "Page": "Trang",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Default": "Mặc định",
    "EffectiveDate": "Ngày hiệu lực",
    "EnterDescription": "Nhập mô tả",
    "EnterCode": "Nhập mã",
    "EnterName": "Nhập tên",
    "EnterDisplayName": "Nhập tên hiển thị",
    "AddNewMenuItems": "Thêm mục thực đơn mới",
    "UpdateMenuItems": "Cập nhật mục thực đơn",
    "FoodCategory": "Danh mục món ăn",
    "SelectedItems": "Mục đã chọn",
    "FoodId": "Mã món ăn",
    "Price": "Giá",
    "ListSelected": "Danh sách đã chọn",
    "AddNewMenu": "Thêm mới thực đơn",
    "MenuUpdate": "Cập nhật thực đơn",
    "MenuDetail": "Chi tiết thực đơn",
    "Sections": "Phần",
    "Items": "Mục",
    "AddNewMenuSection": "Thêm mới phần danh mục"
  },
  "Unit": {
    "Title": "đơn vị tính",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "UnitType": "Thiết lập",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Trang",
    "TotalRecords": "Tổng {{value}} bản ghi",
    "NewUnit": "Đơn vị tính mới",
    "UnitDetail": "Chi tiết đơn vị tính",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Save": "Lưu",
    "Update": "Update",
    "AddNewUnit": "Thêm mới đơn vị tính",
    "UnitUpdate": "Cập nhật đơn vị tính",
    "EnterUnitName": "Nhập tên",
    "EnterUnitCode": "Nhập mã",
    "EnterDisplayName": "Nhập tên hiển thị",
    "EnterDescription": "Nhập mô tả"
  },
  "Kitchen": {
    "Title": "Bếp",
    "List": "Danh sách",
    "Information": "Thông tin",
    "Settings": "Thiết lập",
    "Setting": "Thiết lập",
    "NewButton": "Thêm mới bếp",
    "Name": "Tên",
    "Code": "Mã",
    "ChangeStatus": "Đổi trạng thái",
    "Description": "Mô tả",
    "DisplayName": "Tên hiển thị",
    "IsActive": "Trạng thái",
    "Action": "Thao tác",
    "Page": "Trang",
    "NewKitchen": "Bếp mới",
    "KitchenDetail": "Chi tiết bếp",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "AddNewSettings": "Thêm thiết lập",
    "UpdateTable": "Table update",
    "Update": "Cập nhật",
    "AddNewKitchen": "Thêm mới bếp",
    "KitchenUpdate": "Chi tiết bếp",
    "EnterKitchenName": "Nhập tên bếp",
    "EnterDescription": "Nhập mô tả",
    "EnterCode": "Nhập mã",
    "EnterName": "Nhập tên",
    "EnterMenu": "Nhập thực đơn",
    "EnterDisplayName": "Nhập tên hiển thị",
    "EnterCook": "Nhập danh mục chế biến",
    "EnterPointOfSale": "Nhập tên nhà hàng",
    "EnterNote": "Nhập ghi chú",
    "Note": "Ghi chú"
  },
  "RecipeType": {
    "Title": "Loại công thức",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới loại công thức",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsOptional": "Tùy chọn",
    "IsMany": "Nhiều",
    "IsMultiSelect": "Lựa chọn nhiều",
    "IsActive": "Trạng thái",
    "Action": "Thao tác",
    "Page": "Trang",
    "TotalRecords": "Tổng {{value}} bản ghi",
    "NewRecipeType": "Loại công thức mới",
    "RecipeTypeDetail": "Chi tiết loại công thức",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "AddNewRecipeType": "Thêm mới loại công thức",
    "RecipeTypeUpdate": "Cập nhật loại công thức",
    "EnterRecipeTypeName": "Nhập tên loại công thức",
    "EnterRecipeTypeCode": "Nhập mã loại công thức",
    "EnterDescription": "Nhập mô tả",
    "EnterDisplayName": "Nhập tên hiển thị"
  },
  "Size": {
    "Title": "Kích cỡ",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Note": "Ghi chú",
    "IsActive": "Trạng thái",
    "Action": "Thao tác",
    "Page": "Trang",
    "TotalRecords": "Tổng {{value}} bản ghi",
    "NewSize": "Kích cỡ mới",
    "SizeDetail": "Chi tiết kích cỡ",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "AddNewSize": "Thêm mới",
    "SizeUpdate": "Cập nhật",
    "EnterSizeName": "Nhập tên",
    "EnterSizeCode": "Nhập mã",
    "EnterNote": "Nhập ghi chú",
    "EnterDisplayName": "Nhập tên hiển thị"
  },
  "Sale": {
    "Title": "Bán hàng",
    "NewOrder": "Đơn hàng mới",
    "NewOrderTicket": "Phiếu đặt hàng",
    "RetailOrder": "Đơn hàng lẻ",
    "WholesaleOrder": "Đơn hàng sỉ",
    "SalesManagement": "Quản lý bán hàng",
    "TableManaGeMent": "Quản lý bàn",
    "OrderToday": "Đơn hàng hôm nay",
    "PurchaseOrder": "Đơn hàng"
  },
  "Recipe": {
    "Title": "Công thức",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới công thức",
    "Name": "Tên",
    "Code": "Mã",
    "EndProduct": "Tên thành phẩm",
    "Food": "Món ăn",
    "Size": "Kích cỡ",
    "EndQuantity": "Số lượng thành phẩm",
    "TotalCost": "Tổng chi phí",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsOptional": "Tùy chọn",
    "IsMany": "Nhiều",
    "IsMultiSelect": "Lựa chọn nhiều",
    "IsActive": "Trạng thái",
    "Action": "Thao tác",
    "Page": "Trang",
    "TotalRecords": "Tổng {{value}} bản ghi",
    "NewRecipe": "Công thức mới",
    "RecipeDetail": "Chi tiết công thức",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "AddNewRecipe": "Thêm mới công thức",
    "RecipeUpdate": "Cập nhật công thức",
    "EnterRecipeName": "Nhập tên công thức",
    "EnterRecipeCode": "Nhập mã công thức",
    "EnterDescription": "Nhập mô tả",
    "EnterDisplayName": "Nhập tên hiển thị"
  },
  "Brand": {
    "Title": "nhãn hàng",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Thêm mới",
    "Name": "Tên",
    "Code": "Mã",
    "EndProduct": "End product",
    "Food": "Food",
    "Size": "Size",
    "EndQuantity": "End quantity",
    "TotalCost": "Total cost",
    "DisplayName": "Tên hiển thị",
    "Description": "Description",
    "IsOptional": "Is optional",
    "IsMany": "Is many",
    "IsMultiSelect": "Is multi select",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewBrand": "Nhãn hàng mới",
    "BrandDetail": "Brand detail",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "AddNewBrand": "Add new brand",
    "UpdateBrand": "Cập nhật nhãn hàng",
    "EnterBrandName": "Enter brand name",
    "EnterBrandCode": "Enter brand code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name"
  },
  "Catalog": {
    "Title": "catalog",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "New",
    "Stt": "Stt",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewCatalog": "Catalog mới",
    "CatalogDetail": "Catalog chi tiết",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "AddNewCatalog": "Thêm mới",
    "UpdateCatalog": "Cập nhật catalog",
    "EnterCatalogName": "Nhập tên catalog",
    "EnterCatalogCode": "Nhập mã catalog",
    "EnterDescription": "Nhập mô tả",
    "EnterDisplayName": "Nhập tên hiển thị",
    "SelectCatalog": "Select catalog"
  },
  "Distribution": {
    "Title": "distribution",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewDistribution": "Distribution mới",
    "DistributionDetail": "Distribution chi tiết",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "AddNewDistribution": "Add new distribution",
    "UpdateDistribution": "Cập nhật distribution",
    "EnterDistributionName": "Enter distribution name",
    "EnterDistributionCode": "Enter distribution code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name"
  },
  "MadeInCountry": {
    "Title": "made in country",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewMadeInCountry": "Made in country mới",
    "MadeInCountryDetail": "Made in country chi tiết",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "AddNewMadeInCountry": "Add new made in country",
    "UpdateMadeInCountry": "Cập nhật made in country",
    "EnterMadeInCountryName": "Enter made in country name",
    "EnterMadeInCountryCode": "Enter made in country code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name"
  },
  "Post": {
    "Post": "Post",
    "Title": "Tiêu đề",
    "SearchPlaceholder": "Tìm theo tiêu đề",
    "NewButton": "Mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewPost": "Post mới",
    "PostDetail": "Post chi tiết",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "AddNewPost": "Add new post",
    "UpdatePost": "Cập nhật post",
    "EnterPostName": "Enter post name",
    "EnterPostCode": "Enter post code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name",
    "Content": "Nội dung",
    "Author": "Author",
    "HashTags": "Hashtags"
  },
  "ProductAge": {
    "Title": "product age",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewProductAge": "Product age mới",
    "ProductAgeDetail": "Product age chi tiết",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "AddNewProductAge": "Add new product age",
    "UpdateProductAge": "Cập nhật product age",
    "EnterProductAgeName": "Enter product age name",
    "EnterProductAgeCode": "Enter product age code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name",
    "Content": "Content"
  },
  "Specification": {
    "Title": "specification"
  },
  "ConcentrationUnit": {
    "Title": "concentration unit",
    "Concentration": "Concentration"
  },
  "CapacityUnit": {
    "Title": "capacity unit",
    "Capacity": "Capacity"
  },
  "BusinessType": {
    "Title": "business type",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewBusinessType": "business type mới",
    "BusinessTypeDetail": "business type chi tiết",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "AddNewBusinessType": "Add new business type",
    "UpdateBusinessType": "Cập nhật business type",
    "EnterBusinessTypeName": "Enter business type name",
    "EnterBusinessTypeCode": "Enter business type code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name",
    "Content": "Content"
  },
  "ProductType": {
    "Title": "Loại sản phẩm",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewProductType": "loại sản phẩm mới",
    "ProductTypeDetail": "loại sản phẩm chi tiết",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "AddNewProductType": "Add new loại sản phẩm",
    "UpdateProductType": "Cập nhật loại sản phẩm",
    "EnterProductTypeName": "Enter loại sản phẩm name",
    "EnterProductTypeCode": "Enter loại sản phẩm code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name",
    "Content": "Content"
  },
  "Price": {
    "Title": "Giá",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "NewButton": "Mới",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewPrice": "giá mới",
    "PriceDetail": "price chi tiết",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "AddNewPrice": "Add new price",
    "UpdatePrice": "Cập nhật giá",
    "EnterPriceName": "Enter price name",
    "EnterPriceCode": "Enter price code",
    "EnterDescription": "Enter description",
    "EnterDisplayName": "Enter display name",
    "Content": "Content",
    "PriceType": "Loại giá",
    "ProductPrice": "Giá sản phẩm",
    "OriginalPrice": "Giá trước giảm"
  },
  "PurchaseOrder": {
    "Title": "Đơn hàng",
    "SearchPlaceholder": "Tìm theo mã đơn hàng",
    "NewButton": "Mới",
    "Stt": "Stt",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "IsActive": "Trạng thái",
    "Status": "Trạng thái",
    "Action": "Action",
    "Page": "Page",
    "TotalRecords": "Total {{value}} records",
    "NewPurchaseOrder": "Đơn hàng mới",
    "PurchaseOrderDetail": "Chi tiết đơn hàng",
    "Reset": "Đặt lại",
    "Add": "Thêm mới",
    "Update": "Cập nhật",
    "Save": "Lưu",
    "UpdatePurchaseOrder": "Cập nhật đơn hàng",
    "Guest": "Khách hàng",
    "Employee": "Nhân viên",
    "IsDeliveryAtStore": "Is delivery at store?",
    "IsOnline": "Is online?",
    "Channel": "Channel",
    "Note": "Ghi chú",
    "PurchaseOrderItems": "Purchase order items",
    "NoProduct": "Chưa có sản phẩm",
    "Type": "Loại",
    "Qty": "SL",
    "OrderCode": "Mã ĐH",
    "CreatedAt": "Tạo lúc",
    "OrderInfo": "Thông tin bán hàng",
    "Subtotal": "Tổng tạm tính",
    "OrderTotal": "Tổng đơn hàng",
    "PointOfSale": "Điểm bán hàng",
    "CreatedDate": "Ngày tạo",
    "CreateOrder": "Tạo đơn hàng",
    "ShippingAgent": "Shipping agent",
    "DeliveryAddress": "Địa chỉ giao hàng",
    "DeliveryDate": "Ngày giao hàng",
    "PaymentMethod": "Phương thức thanh toán",
    "ShippingFee": "Phí giao hàng",
    "ShippingFeeNote": "Ghi chú giao hàng",
    "DiscountAmount": "Giảm trừ",
    "DiscountNote": "Ghi chú giảm trừ",
    "TaxAmount": "Thuế phí",
    "TaxNote": "Ghi chú thuế phí",
    "DiscountLargerThanTotal": "Giảm trừ không thể lớn hơn tổng đơn hàng"
  },
  "PaymentMethod": {
    "Cash": "Tiền mặt",
    "Cod": "Cash on delivery",
    "BankTransfer": "Chuyển khoản",
    "Debt": "Công nợ",
    "CreditCard": "Credit card",
    "VnPay": "VNPAY",
    "Momo": "MOMO",
    "BalanceAccount": "Tài khoản dư",
    "COP": "Nhờ thu - C.O.P",
    "OpenAccount": "Ghi sổ - Open account",
    "LC": "Tính dụng chứng từ - L.C",
    "Paypal": "Paypal",
    "Other": "Khác"
  },
  "Language": {
    "Title": "Ngôn ngữ",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "ShortName": "Tên rút gọn",
    "NumCode": "Num code",
    "IsActive": "Trạng thái",
    "NewLanguage": "Ngôn ngữ mới",
    "UpdateLanguage": "Cập nhật ngôn ngữ",
    "Add": "Thêm mới"
  },
  "Category": {
    "Title": "Danh mục",
    "SearchPlaceholder": "Tìm theo tên hoặc mã",
    "Name": "Tên",
    "Code": "Mã",
    "DisplayName": "Tên hiển thị",
    "Description": "Mô tả",
    "ParentCategory": "Danh mục cha",
    "Language": "Ngôn ngữ",
    "IsActive": "Trạng thái",
    "NewCategory": "Danh mục mới",
    "UpdateCategory": "Cập nhật danh mục",
    "Add": "Thêm mới"
  },
  "Slug": {
    "Title": "Slug",
    "SearchPlaceholder": "Tìm theo tên",
    "FullUri": "FullUri",
    "SlugContent": "Slug content",
    "Prefix": "Prefix",
    "TargetType": "Target type",
    "Target": "Target",
    "Language": "Language",
    "IsActive": "Trạng thái",
    "NewSlug": "Slug mới",
    "UpdateSlug": "Cập nhật slug",
    "Add": "Thêm mới"
  },
  "User": {
    "Title": "User",
    "SearchPlaceholder": "Tìm theo username",
    "Add": "Thêm mới",
    "FirstName": "Tên",
    "MiddleName": "Tên đệm",
    "LastName": "Họ",
    "AliasName": "Alias name",
    "Bio": "Bio",
    "Email": "Email",
    "DateOfBirth": "Ngày sinh",
    "PhoneNumber": "Số điện thoại",
    "Address": "Địa chỉ",
    "IdNumber": "Id number",
    "Avatar": "Avatar",
    "Gender": "Giới tính",
    "Website": "Website",
    "UserName": "Username",
    "Password": "Mật khẩu",
    "ConfirmPassword": "Xác nhận mật khẩu",
    "IsActive": "Trạng thái",
    "NewUser": "Người dùng mới",
    "UpdateUser": "Cập nhật người dùng",
    "Male": "Nam",
    "Female": "Nữ",
    "Other": "Khác"
  },
  "Image": {
    "SelectImage": "Chọn hình ảnh",
    "UploadSuccessfully": "Tải lên thành công"
  },
  "Subject": {
    "Title": "Subject",
    "SearchPlaceholder": "Tìm theo mã hoặc tên",
    "Stt": "Stt",
    "Code": "Code",
    "Name": "Name",
    "DisplayName": "Display name",
    "IsActive": "Trạng thái",
    "NewSubject": "Subject mới",
    "UpdateSubject": "Cập nhật subject",
    "NewButton": "Thêm mới"
  },
  "Banner": {
    "Banner": "Banner",
    "Title": "Title",
    "SearchPlaceholder": "Tìm theo title",
    "Stt": "Stt",
    "SubTitle": "Subtitle",
    "BannerType": "Banner type",
    "Article": "Article",
    "Language": "Language",
    "Description": "Description",
    "IsActive": "Trạng thái",
    "NewBanner": "Banner mới",
    "UpdateBanner": "Cập nhật Banner",
    "NewButton": "Thêm mới"
  },
  "BannerType": {
    "ArticleBanner": "Article banner",
    "HeroBanner": "Hero banner",
    "SideBanner": "Side banner",
    "Other": "Other"
  },
  "SaleSetting": {
    "Title": "Sale setting",
    "SearchPlaceholder": "Tìm theo mã",
    "Stt": "Stt",
    "Code": "Code",
    "Version": "Version",
    "EffectiveDate": "Ngày hiệu lực",
    "ExpirationDate": "Expiration date",
    "TaxRate": "Tax rate",
    "TaxMaxAmount": "Tax max amount",
    "TaxMinAmount": "Tax rate",
    "DiscountRate": "Discount rate",
    "DiscountMaxAmount": "Discount max amount",
    "DiscountMinAmount": "Discount min amount",
    "SaleSettingStatus": "Sale setting status",
    "IsActive": "Trạng thái",
    "NewSaleSetting": "Sale setting mới",
    "UpdateSaleSetting": "Cập nhật sale setting",
    "NewButton": "Thêm mới"
  },
  "SaleSettingStatus": {
    "Draft": "Draft",
    "Activated": "Activated",
    "Deactivated": "Deactivated"
  }
}